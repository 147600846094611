import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
// components
import ButtonWithSpinner from "./ButtonWithSpinner";
// translate
import { i18n } from "../translate/i18n";
// services
import api from "../services/api";
// errors
import toastError from "../errors/toastError";
// hooks
import useQueues from "../hooks/useQueues";

const useStyles = makeStyles(() => ({
  maxWidth: {
    width: "100%",
  },
}));

const filterOptions = createFilterOptions({
  trim: true,
});

const TransferTicketModal = ({ modalOpen, onClose, ticketId, connectionId, channel }) => {
  // !
  // Context
  // const { user: loggedInUser } = useContext(AuthContext);
  // History
  const history = useHistory();
  // State
  const [options, setOptions] = useState([]);
  const [queues, setQueues] = useState([]);
  const [allQueues, setAllQueues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedQueue, setSelectedQueue] = useState("");
  // const [selectedWhatsapp, setSelectedWhatsapp] = useState(connectionId);
  // Styles
  const classes = useStyles();
  // Queues
  const { findAll } = useQueues();

  // @
  const memoizedFindAll = useCallback(findAll, []);

  useEffect(() => {
    const loadQueues = async () => {
      const list = await memoizedFindAll();

      setAllQueues(list);
      setQueues(list);
    };

    loadQueues();
  }, [memoizedFindAll]);

  useEffect(() => {
    if (!modalOpen || searchParam.length < 3) {
      setLoading(false);
      return;
    }

    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchUsers = async () => {
        try {
          const { data } = await api.get("/users/", {
            params: { searchParam },
          });
          setOptions(data.users);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };

      fetchUsers();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, modalOpen]);

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") return;

    onClose();
    setSearchParam("");
    setSelectedUser(null);
  };

  const handleSaveTicket = async e => {
    e.preventDefault();

    if (!ticketId) return;

    setLoading(true);

    const isConfirmed = window.confirm("Tem certeza de que deseja transferir?");

    if (isConfirmed) {
      try {
        let data = {};

        if (!selectedUser && selectedUser == null) {
          toast.error("Selecione um usuário válido para transferência");
        } else {
          data.userId = selectedUser.id;
        }

        if (!selectedQueue && selectedQueue == null) {
          toast.error("Selecione um setor válido para transferência");
        } else {
          data.queueId = selectedQueue;
        }

        if (connectionId) {
          data.whatsappId = connectionId;
        }

        if (selectedUser && selectedUser !== null && selectedQueue && selectedQueue !== null) {
          await api.put(`/tickets/${ticketId}`, data, {
            params: { channel }
          });
          setLoading(false);
          history.push("/tickets");
        } else {
          setLoading(false);
          history.push("/tickets");
        }
      } catch (err) {
        setLoading(false);
        toastError(err);
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <Dialog open={modalOpen} onClose={handleClose} maxWidth="lg" scroll="paper">
      <form onSubmit={handleSaveTicket}>
        <DialogTitle id="form-dialog-title">
          {i18n.t("transferTicketModal.title")}
        </DialogTitle>
        <DialogContent dividers>
          <Autocomplete
            style={{ width: 300, marginBottom: 20 }}
            getOptionLabel={option => `${option.name}`}
            onChange={(e, newValue) => {
              setSelectedUser(newValue);
              if (newValue != null && Array.isArray(newValue.queues)) {
                setQueues(newValue.queues);
              } else {
                setQueues(allQueues);
                setSelectedQueue("");
              }
            }}
            options={options}
            filterOptions={filterOptions}
            freeSolo
            autoHighlight
            noOptionsText={i18n.t("transferTicketModal.noOptions")}
            loading={loading}
            renderInput={params => (
              <TextField
                {...params}
                label={i18n.t("transferTicketModal.fieldLabel")}
                variant="outlined"
                required
                autoFocus
                onChange={e => setSearchParam(e.target.value)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
          <FormControl variant="outlined" className={classes.maxWidth}>
            <InputLabel>{i18n.t("transferTicketModal.fieldQueueLabel")}</InputLabel>
            <Select
              value={selectedQueue}
              onChange={(e) => setSelectedQueue(e.target.value)}
              label={i18n.t("transferTicketModal.fieldQueuePlaceholder")}
            >
              <MenuItem value={""}>&nbsp;</MenuItem>
              {queues.map((queue) => (
                <MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <Can
						role={loggedInUser.profile}
						perform="ticket-options:transferWhatsapp"
						yes={() => (!loadingWhatsapps && 
							<FormControl variant="outlined" className={classes.maxWidth} style={{ marginTop: 20 }}>
								<InputLabel>{i18n.t("transferTicketModal.fieldConnectionLabel")}</InputLabel>
								<Select
									value={selectedWhatsapp}
									onChange={(e) => setSelectedWhatsapp(e.target.value)}
									label={i18n.t("transferTicketModal.fieldConnectionPlaceholder")}
								>
									{whatsApps.map((whasapp) => (
										<MenuItem key={whasapp.id} value={whasapp.id}>{whasapp.name}</MenuItem>
									))}
								</Select>
							</FormControl>
						)}
					/> */}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="secondary"
            disabled={loading}
            variant="outlined"
          >
            {i18n.t("transferTicketModal.buttons.cancel")}
          </Button>
          <ButtonWithSpinner
            variant="contained"
            type="submit"
            color="primary"
            loading={loading}
          >
            {i18n.t("transferTicketModal.buttons.ok")}
          </ButtonWithSpinner>
        </DialogActions>
      </form>
    </Dialog>
  );
};
TransferTicketModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  ticketId: PropTypes.number.isRequired,
  connectionId: PropTypes.string,
  channel: PropTypes.string,
};

export default TransferTicketModal;
