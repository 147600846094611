import React, { useState, useEffect, useReducer } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
// components
import TableRowSkeleton from "../components/TableRowSkeleton";
import MainHeader from "../components/MainHeader";
import MainHeaderButtonsWrapper from "../components/MainHeaderButtonsWrapper";
import MainContainer from "../components/MainContainer";
// errors
import toastError from "../errors/toastError";
// services
import api from "../services/api";
// translate
import { i18n } from "../translate/i18n";

const reducer = (state, action) => {
  if (action.type === "LOAD_CONTACTS") {
    const contacts = action.payload;
    const newContacts = [];

    contacts.forEach((contact) => {
      const contactIndex = state.findIndex((c) => c.id === contact.id);
      if (contactIndex !== -1) {
        state[contactIndex] = contact;
      } else {
        newContacts.push(contact);
      }
    });

    return [...state, ...newContacts];
  }

  if (action.type === "UPDATE_CONTACTS") {
    const contact = action.payload;
    const contactIndex = state.findIndex((c) => c.id === contact.id);

    if (contactIndex !== -1) {
      state[contactIndex] = contact;
      return [...state];
    } else {
      return [contact, ...state];
    }
  }

  if (action.type === "DELETE_CONTACT") {
    const contactId = action.payload;

    const contactIndex = state.findIndex((c) => c.id === contactId);
    if (contactIndex !== -1) {
      state.splice(contactIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET_CONTACTS") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const Contacts = () => {
  // !
  // Reducer
  const [contactsFacebookPages, dispatchFacebookPages] = useReducer(reducer, []);
  // State
  const [loadingFacebookPages, setLoadingFacebookPages] = useState(false);
  const [searchParamFacebookPages, ] = useState("");
  const [pageNumberFacebookPages, setPageNumberFacebookPages] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  // Styles
  const classes = useStyles();

  // @
  useEffect(() => {
    dispatchFacebookPages({ type: "RESET_CONTACTS" });

    setPageNumberFacebookPages(1);
  }, []);

  // @ Fetch contacts
  useEffect(() => {
    setLoadingFacebookPages(true);

    const delayDebounceFn2 = setTimeout(() => {
      const fetchFacebookContacts = async () => {
        try {
          const { data: facebookData } = await api.get("/contacts", {
            params: { channel: "facebook", searchParamFacebookPages, pageNumberFacebookPages },
          });

          dispatchFacebookPages({ type: "LOAD_CONTACTS", payload: facebookData.contacts });

          setLoadingFacebookPages(false);
          setHasMore(facebookData.hasMore);
        } catch (err) {
          console.error(err);
          toastError(err);
        }
      };

      fetchFacebookContacts();
    }, 500);

    return () => clearTimeout(delayDebounceFn2);
  }, [searchParamFacebookPages, pageNumberFacebookPages]);

  // # Pagination
  const loadMore = () => {
    setPageNumberFacebookPages((prevState) => prevState + 1);
  };
  const handleScroll = (e) => {
    if (!hasMore || loadingFacebookPages) {
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer className={classes.mainContainer}>
      <MainHeader>
        <MainHeaderButtonsWrapper>
          {/*<TextField
            placeholder={i18n.t("contacts.facebook.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenContactModal}
          >
            {i18n.t("contacts.facebook.buttons.add")}
          </Button>*/}
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
        style={{ marginBottom: "20px" }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell>{i18n.t("contacts.facebook.table.name")}</TableCell>
              <TableCell align="center">
                {i18n.t("contacts.facebook.table.id")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {contactsFacebookPages.map((contact) => (
                <TableRow key={contact.id}>
                  <TableCell style={{ paddingRight: 0 }}>
                    {<Avatar src={contact.profilePicUrl} />}
                  </TableCell>
                  <TableCell>{contact.name}</TableCell>
                  <TableCell align="center">{contact.facebookUserId}</TableCell>
                  {/*<TableCell align="center">
                    {/*  <IconButton
                      size="small"
                      onClick={() => handleSaveTicket(contact.id)}
                    >
                      <WhatsAppIcon />
                    </IconButton> 
                    <IconButton
                      size="small"
                      onClick={() => hadleEditContact(contact.id)}
                    >
                      <EditIcon />
                    </IconButton>
                    <Can
                      role={user.profile}
                      perform="contacts-page:deleteContact"
                      yes={() => (
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            setConfirmOpen(true);
                            setDeletingContact(contact);
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      )}
                    /> 
                  </TableCell>*/}
                </TableRow>
              ))}
              {loadingFacebookPages && <TableRowSkeleton avatar columns={1} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Contacts;
