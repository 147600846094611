import axios from "axios";
// configs
import { getBackendUrl } from "../config";

const api = axios.create({
  baseURL: getBackendUrl(),
  withCredentials: true,
});
export const openApi = axios.create({
  baseURL: getBackendUrl(),
  withCredentials: true,
});

export default api;
