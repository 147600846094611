import React from "react";
import { Menu, MenuItem } from "@material-ui/core";
import PropTypes from "prop-types";
// services
import api from "../services/api";
// errors
import toastError from "../errors/toastError";
// translate
import { i18n } from "../translate/i18n";

const TicketOptionsMenu = ({ ticket, anchorEl, handleClose }) => {
  const handleMarkAsUnread = async () => {
    try {
      await api.put(`/tickets/${ticket.id}/markAsUnread`);
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  const handleMarkAsRead = async () => {
    try {
      await api.put(`/tickets/${ticket.id}/markAsRead`);
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      getContentAnchorEl={null}
      anchorReference="anchorEl"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {ticket.unreadMessages === -1 ? (
        <MenuItem onClick={handleMarkAsRead}>
          {i18n.t("ticketOptionsMenu.markAsRead")}
        </MenuItem>
      ) : (
        <MenuItem onClick={handleMarkAsUnread}>
          {i18n.t("ticketOptionsMenu.markAsUnread")}
        </MenuItem>
      )}
    </Menu>
  );
};

TicketOptionsMenu.propTypes = {
  ticket: PropTypes.object.isRequired,
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
};

export default TicketOptionsMenu;
