import React, { useContext, useEffect, useState } from "react";
import { Paper, Tabs, Tab, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// assets
import whatsappIcon from "../../assets/channels/WhatsApp_Glyph_Green.png";
import facebookIcon from "../../assets/channels/Facebook_Logo_Primary.png";
import instagramIcon from "../../assets/channels/Instagram_Glyph_Gradient.png";
// components
import ContactsListFacebook from "../../components/ContactsListFacebook";
import ContactsListInstagram from "../../components/ContactsListInstagram";
import ContactsListWhatsapp from "../../components/ContactsListWhatsapp";
// contexts
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles(() => ({
  tabsHeader: {
    flex: "none",
    backgroundColor: "#eee",
  },
  tabsContainer: {
    backgroundColor: "#fff",
  },
  selectedTab: {
    backgroundColor: "#fff",
  },
}));

const Contacts = () => {
  // !
  // Contexts
  const { checkLogin } = useContext(AuthContext);
  // States
  const [activeTab, setActiveTab] = useState(0);
  // Styles
  const classes = useStyles();

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // @
  useEffect(() => {
    checkLogin();
  }, [checkLogin]);

  return (
    <Paper elevation={0} square className={classes.tabsHeader}>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab
          icon={<img src={whatsappIcon} alt="WhatsApp" style={{ width: 24, height: 24 }} />}
          label="WhatsApp"
          classes={{ selected: classes.selectedTab }}
        />
        <Tab
          icon={<img src={facebookIcon} alt="Facebook" style={{ width: 24, height: 24 }} />}
          label="Facebook"
          classes={{ selected: classes.selectedTab }}
        />
        <Tab
          icon={<img src={instagramIcon} alt="Instagram" style={{ width: 24, height: 24 }} />}
          label="Instagram"
          classes={{ selected: classes.selectedTab }}
        />
      </Tabs>
      <Box className={classes.tabsContainer}>
        {activeTab === 0 && <ContactsListWhatsapp />}
        {activeTab === 1 && <ContactsListFacebook />}
        {activeTab === 2 && <ContactsListInstagram />}
      </Box>
    </Paper>
  );
};

export default Contacts;
