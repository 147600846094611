import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Paper, makeStyles } from "@material-ui/core";
import clsx from "clsx";

import SearchIcon from "@material-ui/icons/Search";

import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
// contexts
import { AuthContext } from "../context/Auth/AuthContext";
import { ReplyMessageProvider } from "../context/ReplyingMessage/ReplyingMessageContext";
// errors
import toastError from "../errors/toastError";
// hooks
import { useQuery } from "../hooks/useQuery";
// services
import openSocket from "../services/socket-io";
import api from "../services/api";
// components
import ContactDrawer from "./ContactDrawer";
import MessageInput from "./MessageInput";
import TicketHeader from "./TicketHeader";
import TicketInfo from "./TicketInfo";
import TicketActionButtons from "./TicketActionButtons";
import MessagesList from "./MessagesList";
import MessagesListFacebookPages from "./MessagesListFacebookPages";
import MessagesListInstagram from "./MessagesListInstagram";
import MessageInputFacebookPages from "./MessageInputFacebookPages";
import MessageInputInstagram from "./MessageInputInstagram";
import { TagsContainer } from "./TagsContainer";
import MessageSearchDrawer from "./MessageSearchDrawer";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "199%",
    position: "relative",
    overflow: "hidden",
  },

  ticketInfo: {
    maxWidth: "50%",
    flexBasis: "50%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
      flexBasis: "80%",
    },
    paddingLeft: 0,
  },
  ticketActionButtons: {
    maxWidth: "50%",
    flexBasis: "50%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      flexBasis: "100%",
      marginBottom: "5px",
    },
  },

  mainWrapper: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: "0",
    marginRight: -drawerWidth,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  mainWrapperShift: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

const Ticket = () => {
  // !
  // Context
  const { user } = useContext(AuthContext);
  // History
  const history = useHistory();
  // Params
  const { ticketId } = useParams();
  // Query
  const query = useQuery();
  // State
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState({});
  const [ticket, setTicket] = useState({});
  const [searchDrawerOpen, setSearchDrawerOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [highlightedMessageId, setHighlightedMessageId] = useState(null);
  // Styles
  const classes = useStyles();

  // @ Get route query
  const channel = query.get("channel");

  // @
  useEffect(() => {
    setSearchDrawerOpen(false); // @ Close the search drawer when the ticket is changed
    setSearchResults([]); // @ Clear the search results when the ticket is changed
    setLoading(true);

    const delayDebounceFn = setTimeout(() => {
      const fetchTicket = async () => {
        try {
          const params = {
            channel
          };

          const { data } = await api.get("/tickets/" + ticketId, { params });

          setContact(data.contact);
          setTicket(data);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };

      fetchTicket();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [channel, ticketId, history]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("connect", () => socket.emit("joinChatBox", ticketId));

    socket.on("ticket", (data) => {
      if (data.action === "update") {
        setTicket(data.ticket);
      }

      if (data.action === "delete") {
        toast.success("Ticket deleted sucessfully.");
        history.push("/tickets");
      }
    });

    socket.on("contact", (data) => {
      if (data.action === "update") {
        setContact((prevState) => {
          if (prevState.id === data.contact?.id) {
            return { ...prevState, ...data.contact };
          }
          return prevState;
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [ticketId, history]);

  const handleDrawerOpen = () => {
    setSearchDrawerOpen(false);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleSearchDrawerOpen = () => {
    setDrawerOpen(false);
    setSearchDrawerOpen(true);
  };

  const handleSearchDrawerClose = () => {
    setSearchDrawerOpen(false);
  };

  const handleSearch = async (term, pageNumber = 1) => {
    setSearchTerm(term);

    if (!term || term.length < 2) {
      setSearchResults([]);
      setPage(1); // @ Reset page number when clearing the search term

      return;
    }

    try {
      const { data } = await api.get(`/tickets/${ticketId}/messages/search`, {
        params: { term, pageNumber, pageSize: 15 },
      });

      setSearchResults((prevResults) => (pageNumber === 1 ? data.messages : [...prevResults, ...data.messages]));
      setHasMore(data.hasMore);
      setPage(pageNumber); // @ Update the current page number
    } catch (err) {
      toastError(err);
    }
  };

  const handleLoadMore = () => {
    if (hasMore) {
      const nextPage = page + 1;

      return new Promise((resolve) => {
        setTimeout(() => {
          handleSearch(searchTerm, nextPage).then(resolve);
        }, 1000);
      });
    }

    return Promise.resolve();
  };

  const handleHighlightMessage = (messageId) => {
    setHighlightedMessageId(null);

    setTimeout(() => {
      setHighlightedMessageId(messageId);
    }, 0);
  };

  // # Omnichannel
  const renderMessagesList = (channel, highlightedMessageId) => {
    if (channel === "facebook") {
      return <MessagesListFacebookPages ticketId={ticketId} />;
    } else if (channel === "instagram") {
      return <MessagesListInstagram ticketId={ticketId} />;
    } else {
      return <MessagesList ticketId={ticketId} isGroup={ticket.isGroup ?? false} highlightedMessageId={highlightedMessageId} />;
    }
  };
  const renderTagList = (channel) => {
    if (channel !== "facebook" && channel !== "instagram") {
      return <TagsContainer ticket={ticket} />;
    }
  };
  const renderMessageInput = () => {
    
    if (user.profile === "supervisor") {
      return null;
    }
    
    if (channel === "facebook") {
      return <MessageInputFacebookPages ticketStatus={ticket.status} />;
    } else if (channel === "instagram") {
      return <MessageInputInstagram ticketStatus={ticket.status} />;
    } else {
      return <MessageInput ticketStatus={ticket.status} queue={ticket.queue}/>;
    }
  };

  return (
    <div className={classes.root} id="drawer-container">
      <Paper
        variant="outlined"
        elevation={0}
        className={clsx(classes.mainWrapper, {
          [classes.mainWrapperShift]: channel === "facebook" || channel === "instagram" || drawerOpen || searchDrawerOpen,
        })}
      >
        <TicketHeader loading={loading}>
          <ListItem button className={classes.ticketInfo} onClick={handleDrawerOpen}>
            <TicketInfo
              contact={contact}
              user={user}
              ticket={ticket}
              onClick={handleDrawerOpen}
            />
          </ListItem>
          <div className={classes.ticketActionButtons}>
            <TicketActionButtons ticket={ticket} />
            <IconButton onClick={handleSearchDrawerOpen}>
              <SearchIcon />
            </IconButton>
          </div>
        </TicketHeader>
        <Paper>
          {renderTagList(channel)}
        </Paper>
        <ReplyMessageProvider>
          {renderMessagesList(channel, highlightedMessageId)}          

          {renderMessageInput()}
        </ReplyMessageProvider>
      </Paper>

      {channel !== "facebook" && channel !== "instagram" && (
        <>
          {!searchDrawerOpen && (
            <ContactDrawer
              open={drawerOpen}
              handleDrawerClose={handleDrawerClose}
              contact={contact}
              loading={loading}
            />
          )}
          {!drawerOpen && searchDrawerOpen && (
            <MessageSearchDrawer
              open={searchDrawerOpen}
              handleDrawerClose={handleSearchDrawerClose}
              searchTerm={searchTerm}
              searchResults={searchResults}
              onSearch={handleSearch}
              contactName={contact.name}
              onLoadMore={handleLoadMore}
              hasMore={hasMore}
              onHighlightMessage={handleHighlightMessage}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Ticket;
