import { Box, Chip, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import toastError from "../../errors/toastError";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";

export function UsersFilter({ onFiltered, initialUsers }) {
  const [users, setUsers] = useState([]);
  const [selecteds, setSelecteds] = useState([]);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function fetchData() {
      await loadUsers();
    }
    fetchData();
  }, []);

  useEffect(() => {
    setSelecteds([]);
    if (
      Array.isArray(initialUsers) &&
      Array.isArray(users) &&
      users.length > 0
    ) {
      onChange(initialUsers);
    }
  }, [initialUsers, users]);

  const loadUsers = async () => {
    try {
      const { data } = await api.get("/users/list");
      let userList = data.map((u) => ({ 
        id: u.id, 
        name: u.profile === "supervisor" 
          ? "Supervisor: " + u.name 
          : "Agente: " + u.name,
        priority: u.profile === "supervisor" ? 1 : 2,
      }))
        .sort((a, b) => a.priority - b.priority) 
        .map(({ priority, ...rest }) => rest);

      userList = userList.filter((u) => u.id !== user.id);

      setUsers(userList);
    } catch (err) {
      toastError(err);
    }
  };

  const onChange = async (value) => {
    setSelecteds(value);
    onFiltered(value);
  };

  return (
    <Box style={{ padding: "0px 10px 10px" }}>
      <Autocomplete
        multiple
        size="small"
        options={users}
        value={selecteds}
        onChange={(e, v, r) => onChange(v)}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, value) => {
          return (
            option?.id === value?.id ||
            option?.name.toLowerCase() === value?.name.toLowerCase()
          );
        }}
        renderTags={(value, getUserProps) =>
          value.map((option, index) => (
            <Chip
              key={option.id}
              variant="outlined"
              style={{
                backgroundColor: "#bfbfbf",
                textShadow: "1px 1px 1px #000",
                color: "white",
              }}
              label={option.name}
              {...getUserProps({ index })}
              size="small"
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Filtro por Users"
          />
        )}
      />
    </Box>
  );
}
UsersFilter.propTypes = {
  onFiltered: PropTypes.func.isRequired,
  initialUsers: PropTypes.array,
};
