import React, { useEffect, useReducer, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { toast } from "react-toastify";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import Typography from "@material-ui/core/Typography";

// components
import TableRowSkeleton from "../components/TableRowSkeleton";
import ContactModal from "../components/ContactModal";
import MainHeader from "../components/MainHeader";
import MainHeaderButtonsWrapper from "../components/MainHeaderButtonsWrapper";
import MainContainer from "../components/MainContainer";
import TicketsAgentsSelect from "./TicketsAgentsSelect";
// errors
import toastError from "../errors/toastError";
// services
import api from "../services/api";
// translate
import { i18n } from "../translate/i18n";

const reducer = (state, action) => {
  if (action.type === "LOAD_CONTACTS") {
    const contacts = action.payload;
    const newContacts = [];

    contacts.forEach((contact) => {
      const contactIndex = state.findIndex((c) => c.id === contact.id);
      if (contactIndex !== -1) {
        state[contactIndex] = contact;
      } else {
        newContacts.push(contact);
      }
    });

    return [...state, ...newContacts];
  }

  if (action.type === "UPDATE_CONTACTS") {
    const contact = action.payload;
    const contactIndex = state.findIndex((c) => c.id === contact.id);

    if (contactIndex !== -1) {
      state[contactIndex] = contact;
      return [...state];
    } else {
      return [contact, ...state];
    }
  }

  if (action.type === "DELETE_CONTACT") {
    const contactId = action.payload;

    const contactIndex = state.findIndex((c) => c.id === contactId);
    if (contactIndex !== -1) {
      state.splice(contactIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET_CONTACTS") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const Contacts = () => {
  // !
  // Reducer
  const [contacts, dispatch] = useReducer(reducer, []);
  // State
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [agentList, setAgentList] = useState([]);
  let [selectedAgentIds, setSelectedAgentIds] = useState([]);
  let [fromSelectedAgentIds, setfromSelectedAgentIds] = useState([]);
  const [toSelectedAgentIds, settoSelectedAgentIds] = useState([]);
  // Styles
  const classes = useStyles();

  // @
  useEffect(() => {
    dispatch({ type: "RESET_CONTACTS" });

    setPageNumber(1);
  }, []);

  // @ Fetch contacts
  useEffect(() => {
    setLoading(true);

    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {

        try {
          if (fromSelectedAgentIds.length !== 0 || selectedAgentIds.length !== 0) {
            const { data: whatsappData } = await api.get("/contacts", {
              params: { channel: "whatsapp", type: "list", agentId: selectedAgentIds, agentFromId: fromSelectedAgentIds, searchParam, pageNumber },
            });

            dispatch({ type: "RESET_CONTACTS" });
            dispatch({ type: "LOAD_CONTACTS", payload: whatsappData.contacts });

            setLoading(false);
            setHasMore(whatsappData.hasMore);
          } else {
            setLoading(false);
          }
        } catch (err) {
          console.error(err);
          toastError(err);
        }
      };

      fetchContacts();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, selectedAgentIds, fromSelectedAgentIds]);

  // @ Fetch and set `AgentList`
  useEffect(() => {
    const fetchAgents = async () => {
      const { data } = await api.get("/users/");

      setAgentList(data.users);
    };

    fetchAgents();
  }, []);

  const handleUpdateContacts = async () => {
    try {

      if (toSelectedAgentIds.length === 0) {
        toast.error(i18n.t("contactModal.toAgentIdNotDefined"));
        return;
      }
      if (contacts.length === 0) {
        toast.error(i18n.t("contactModal.contactsNotDefined"));
        return;
      }
      if(selectedAgentIds === toSelectedAgentIds){
        toast.error("Não é possível transferir quando o agente atual e o agente de destino são o mesmo");
        return;
      }
  
      // Criação do objeto values
      const values = {
        agentId: toSelectedAgentIds,
        contacts: contacts, // Certifique-se de que contacts tenha o formato esperado pela API
      };

      await api.put("/contacts-transfer/", values);

      toast.success(i18n.t("contactModal.success"));
    } catch (err) {
      console.error("Erro ao atualizar contatos:", err);

      // Trata e exibe o erro de forma amigável
      if (err.response && err.response.data && err.response.data.error) {
        // Exibe o erro retornado pela API, se disponível
        toast.error(err.response.data.error);
      } else {
        // Exibe um erro genérico caso não tenha detalhes
        toast.error(i18n.t("contactModal.error"));
      }
    }
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleClearAgentsSelection = () => {
    setSelectedAgentIds([]);
    setfromSelectedAgentIds([]);
    settoSelectedAgentIds([]);
    dispatch({ type: "RESET_CONTACTS" });
  };

  // # Pagination
  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };
  const handleScroll = (e) => {
    if (!hasMore || loading) {
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const handleCloseContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(false);
  };
  const handleEditContact = (contactId) => {
    setSelectedContactId(contactId);
    setContactModalOpen(true);
  };

  return (
    <MainContainer className={classes.mainContainer}>
      <ContactModal
        open={contactModalOpen}
        onClose={handleCloseContactModal}
        aria-labelledby="form-dialog-title"
        contactId={selectedContactId}
      ></ContactModal>

      <MainHeader>
        <MainHeaderButtonsWrapper style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", flexWrap: "wrap" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start", // Ajusta alinhamento horizontal
              gap: "10px", // Espaçamento entre itens
              flexWrap: "nowrap", // Evita quebra de linha
              width: "100%", // Garante que use todo o espaço horizontal
              overflowX: "auto", // Permite rolagem horizontal, se necessário
            }}
          >
            <Typography
              style={{ marginBottom: 8, marginTop: 12 }}
              variant="subtitle1"
            >
              Agente anterior:
            </Typography>
            <TicketsAgentsSelect
              userAgents={agentList}
              selectedAgentIds={fromSelectedAgentIds}
              onChange={(values) => setfromSelectedAgentIds(values)}
              multiple={false}
            />
            <Typography
              style={{ marginBottom: 8, marginTop: 12 }}
              variant="subtitle1"
            >
              Agente atual:
            </Typography>
            <TicketsAgentsSelect
              userAgents={agentList}
              selectedAgentIds={selectedAgentIds}
              onChange={(values) => setSelectedAgentIds(values)}
              multiple={false}
            />
            <Typography
              style={{ marginBottom: 8, marginTop: 12 }}
              variant="subtitle1"
            >
              Agente destino:
            </Typography>
            <TicketsAgentsSelect
              userAgents={agentList}
              selectedAgentIds={toSelectedAgentIds}
              onChange={(values) => settoSelectedAgentIds(values)}
              multiple={false}
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClearAgentsSelection}
            >
              Limpar filtros
            </Button>
          </div>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <MainHeader>
        <MainHeaderButtonsWrapper style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", flexWrap: "wrap" }}>
          <TextField
            placeholder={i18n.t("contacts.whatsapp.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            style={{ width: "400px" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdateContacts}
          >
            {"Transferir contatos"}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>

      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
        style={{ marginBottom: "20px" }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell>{i18n.t("contacts.whatsapp.table.name")}</TableCell>
              <TableCell align="center">
                {i18n.t("contacts.whatsapp.table.number")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("contacts.whatsapp.table.email")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("contacts.whatsapp.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {contacts.map((contact) => (
                <TableRow key={contact.id}>
                  <TableCell style={{ paddingRight: 0 }}>
                    {<Avatar src={contact.profilePicUrl} />}
                  </TableCell>
                  <TableCell>{contact.name}</TableCell>
                  <TableCell align="center">{contact.number}</TableCell>
                  <TableCell align="center">{contact.email}</TableCell>
                  <TableCell align="center">
                    {/*  <IconButton
                      size="small"
                      onClick={() => handleSaveTicket(contact.id)}
                    >
                      <WhatsAppIcon />
                    </IconButton> */}
                    <IconButton
                      size="small"
                      onClick={() => handleEditContact(contact.id)}
                    >
                      <EditIcon />
                    </IconButton>
                    {/* <Can
                      role={user.profile}
                      perform="contacts-page:deleteContact"
                      yes={() => (
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            setConfirmOpen(true);
                            setDeletingContact(contact);
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      )}
                    /> */}
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton avatar columns={3} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Contacts;
