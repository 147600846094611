import React from "react";
import Cookies from "js-cookie";
import { HashRouter, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Route from "./Route";
// contexts
import { AuthProvider } from "../context/Auth/AuthContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
// layouts
import LoggedInLayout from "../layout";
// pages
import Campaigns from "../pages/Campaigns/";
import CampaignsConfigs from "../pages/CampaignsConfigs";
import CampaignsReport from "../pages/CampaignsReport";

import Connections from "../pages/Connections/";

import ContactLists from "../pages/ContactLists/";
import ContactListItems from "../pages/ContactListItems/";
import Contacts from "../pages/Contacts/";
import ContactsTransfer from "../pages/ContactsTransfer/";

import Dashboard from "../pages/Dashboard/";

import Login from "../pages/Login/";

import Schedules from "../pages/Schedules";

import Tickets from "../pages/Tickets/";

import Tags from "../pages/Tags/";
import Kanban from "../pages/Kanban";
import Chat from "../pages/Chat";

const Routes = () => {
  const redirectTo = Cookies.get("redirect_to") ?? "/connections";

  return (
    <HashRouter>
      <AuthProvider>
        <Switch>
          <Route exact path="/login" component={Login} />
          <WhatsAppsProvider>
            <LoggedInLayout>
              <Route exact path="/" isPrivate>
                <Redirect to={redirectTo} />
              </Route>

              <Route exact path="/campaigns" component={Campaigns} isPrivate />
              <Route exact path="/campaigns-configs" component={CampaignsConfigs} isPrivate />
              <Route exact path="/campaign/:campaignId/report" component={CampaignsReport} isPrivate />

              <Route exact path="/connections" component={Connections} isPrivate />

              <Route exact path="/contacts" component={Contacts} isPrivate />
              <Route exact path="/contacts-transfer" component={ContactsTransfer} isPrivate />
              <Route exact path="/contact-lists" component={ContactLists} isPrivate />
              <Route exact path="/contact-lists/:contactListId/contacts" component={ContactListItems} isPrivate />

              <Route exact path="/dashboard" component={Dashboard} isPrivate />

              <Route exact path="/tickets/:ticketId?" component={Tickets} isPrivate />

              <Route exact path="/schedules" component={Schedules} isPrivate />
              <Route exact path="/tags" component={Tags} isPrivate />
              <Route exact path="/kanban" component={Kanban} isPrivate />
              <Route exact path="/chats/:id?" component={Chat} isPrivate />
            </LoggedInLayout>
          </WhatsAppsProvider>
        </Switch>
        <ToastContainer autoClose={3000} />
      </AuthProvider>
    </HashRouter>
  );
};

export default Routes;
