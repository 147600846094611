import React from "react";
import PropTypes from "prop-types";
// components
import Audio from "./Audio";
import Video from "./Video";
import LocationPreview from "./LocationPreview";
import ModalImageCors from "./ModalImageCors";
// import VcardPreview from "./VcardPreview";

const MessageAttachmentFacebook = ({ message }) => {
  const checkMessageMedia = (message) => {
    let attachments;
    try {
      attachments = JSON.parse(message.attachments);
    } catch (e) {
      attachments = message.attachments;
    }

    for (let i = 0; i < attachments.length; i += 1) {
      const attachmentType = attachments[i].type;
      const url = attachments[i].payload?.url || null;

      switch (attachmentType) {
        case "audio":
          return <Audio url={url} isFullUrl={true} />;
        case "location":
          return (
            <LocationPreview
              lat={attachments[i].payload.coordinates.lat}
              long={attachments[i].payload.coordinates.long}
            />
          );
        case "image":
          return <ModalImageCors imageUrl={url} />;
        case "video":
          return <Video url={url} isFullUrl={true} />;
      }
    }
  };

  return (
    <>
      {checkMessageMedia(message)}
    </>
  );
};
MessageAttachmentFacebook.propTypes = {
  message: PropTypes.object,
};

export default MessageAttachmentFacebook;