import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";

import TransferList from "../TransferList";

import api from "../../services/api";

import toastError from "../../errors/toastError";

const ImportContactsModal = ({ open, onClose, contactListId }) => {
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const { data } = await api.get("/contacts", {
          params: {
            type: "list",
          }
        });
        setLeft(data.contacts);
      } catch (err) {
        toastError(err);
      }
    };

    fetchContacts();
  }, []);

  const handleSave = async () => {
    try {
      const contacts = right.map(contact => ({
        id: contact.id,
        name: contact.name,
        email: contact.email,
        number: contact.number
      }));
      const { data } = await api.post("/contact-list-items/import", { contactListId, contacts });
      
      if (data.length > 0) {
        toast.success(`${data.length} contatos importados com sucesso.`);
      } else {
        toast.error("Nenhum contato foi importado.");
      }
      
      onClose();
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Importar Contatos</DialogTitle>
      <DialogContent style={{ overflowY: "visible" }}>
        <TransferList
          left={left}
          right={right}
          setLeft={setLeft}
          setRight={setRight}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          color="secondary"
        >
          Cancelar
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
        >
          Importar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
ImportContactsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  contactListId: PropTypes.number.isRequired,
};

export default ImportContactsModal;