import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { makeStyles } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";

import Rating from "@material-ui/lab/Rating";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles(() => ({
  on: {
    color: green[600],
    fontSize: "20px"
  },
  off: {
    color: red[600],
    fontSize: "20px"
  },
  pointer: {
    cursor: "pointer"
  }
}));

export function RatingBox ({ rating }) {
  const ratingTrunc = rating === null ? 0 : Math.trunc(rating);
  return <Rating
    defaultValue={ratingTrunc}
    max={5}
    readOnly
  />;
}
RatingBox.propTypes = {
  rating: PropTypes.number
};

export default function TableTicketAgentRating(props) {
  // !
  const { loading, data } = props;
  // Styles
  const classes = useStyles();

  function renderList () {
    return data.map((a, k) => (
      <TableRow key={k}>
        <TableCell>{a.user.name}</TableCell>
        <TableCell>{a.totalTickets}</TableCell>
        <TableCell
          align="center"
          title="1 - Muito insatisfeito, 2 - Insatisfeito, 3 - Neutro, 4 - Satisfeito, 5 - Muito satisfeito"
          className={classes.pointer}
        >
          <RatingBox rating={a.avgRate} />
        </TableCell>
        <TableCell align="center">{formatTime(a.avgSupportTime, 2)}</TableCell>
        <TableCell align="center">
          { a.isOnline ?
            <CheckCircleIcon className={classes.on} />
            : <ErrorIcon className={classes.off} />
          }
        </TableCell>
      </TableRow>
    ));
  }

  function formatTime(minutes){
    return moment().startOf("day").add(minutes, "minutes").format("HH[h] mm[m]");
  }

  return ( !loading ?
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nome do Agente</TableCell>
            <TableCell>Tickets Atendidos</TableCell>
            <TableCell align="center">Avaliação Média</TableCell>
            <TableCell align="center">T.M. de Atendimento</TableCell>
            <TableCell align="center">Status Online</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { renderList() }
        </TableBody>
      </Table>
    </TableContainer>
    : <Skeleton variant="rect" height={150} />
  );
}
TableTicketAgentRating.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array
};
