import React, { useContext } from "react";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
// contexts
import { AuthContext } from "../../context/Auth/AuthContext";
// components
import TicketsManager from "../../components/TicketsManager";
import TicketsManagerAll from "../../components/TicketsManagerAll";
import Ticket from "../../components/Ticket";

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  chatContainer: {
    flex: 1,
    // // backgroundColor: "#eee",
    // padding: theme.spacing(4),
    height: "100%",
    overflowY: "hidden",
  },

  chatPapper: {
    // backgroundColor: "red",
    display: "flex",
    height: "100%",
  },

  contactsWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
  },
  contactsWrapperSmall: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  messagessWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
  },
  welcomeMsg: {
    backgroundColor: "#eee",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%",
    textAlign: "center",
    borderRadius: 0,
  },
  ticketsManager: {},
  ticketsManagerClosed: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const Chat = () => {
  // !
  // Context
  const { user, handleLogout } = useContext(AuthContext);
  // Styles
  const classes = useStyles();
  // Params
  const { ticketId } = useParams();

  // ?
  // Email
  const email_agente = Cookies.get("email_agente");
  const email_cliente = Cookies.get("email_cliente");
  const email_supervisor = Cookies.get("email_supervisor");
  // Profile
  let user_profile;
  if (email_cliente) {
    user_profile = "admin";
  }
  else if (email_supervisor) {
    user_profile = "supervisor";
  }
  else if (email_agente) {
    user_profile = "agente";
  }

  const user_email_encrypted = email_agente || email_cliente || email_supervisor;
  const user_email = atob(user_email_encrypted);
  if ( (user?.email && user.email !== user_email) || user?.profile !== user_profile) {
    handleLogout();
  }

  return (
    <div className={classes.chatContainer}>
      <div className={classes.chatPapper}>
        <Grid container spacing={0}>
          {/* <Grid item xs={4} className={classes.contactsWrapper}> */}
          <Grid
            item
            xs={12}
            md={5}
            className={
              ticketId ? classes.contactsWrapperSmall : classes.contactsWrapper
            }
          >
            {user.profile === "supervisor" ? (<TicketsManagerAll />) : (<TicketsManager />)}
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            className={classes.messagessWrapper}
          >
            {/* <Grid item xs={8} className={classes.messagessWrapper}> */}
            {ticketId ? (
              <>
                <Ticket />
              </>
            ) : (
              <Hidden only={["sm", "xs"]}>
                <Paper className={classes.welcomeMsg}>
                  {/* <Paper square variant="outlined" className={classes.welcomeMsg}> */}
                  <span>{i18n.t("chat.noTicketMessage")}</span>
                </Paper>
              </Hidden>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Chat;
