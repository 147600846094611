import React, { useContext, useEffect, useReducer, useRef, useState } from "react";

import {CSVLink} from "react-csv";

import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";

// context
import { AuthContext } from "../context/Auth/AuthContext";
import { SocketContext } from "../context/Socket/SocketContext";
// components
import TableRowSkeleton from "../components/TableRowSkeleton";
import ContactModal from "../components/ContactModal";
import ConfirmationModal from "../components/ConfirmationModal";
import MainHeader from "../components/MainHeader";
import MainHeaderButtonsWrapper from "../components/MainHeaderButtonsWrapper";
import MainContainer from "../components/MainContainer";
// errors
import toastError from "../errors/toastError";
// services
import api from "../services/api";
// translate
import { i18n } from "../translate/i18n";

const reducer = (state, action) => {
  if (action.type === "LOAD_CONTACTS") {
    const contacts = action.payload;
    const newContacts = [];

    contacts.forEach((contact) => {
      const contactIndex = state.findIndex((c) => c.id === contact.id);
      if (contactIndex !== -1) {
        state[contactIndex] = contact;
      } else {
        newContacts.push(contact);
      }
    });

    return [...state, ...newContacts];
  }

  if (action.type === "UPDATE_CONTACTS") {
    const contact = action.payload;
    const contactIndex = state.findIndex((c) => c.id === contact.id);

    if (contactIndex !== -1) {
      state[contactIndex] = contact;
      return [...state];
    } else {
      return [contact, ...state];
    }
  }

  if (action.type === "DELETE_CONTACT") {
    const contactId = action.payload;

    const contactIndex = state.findIndex((c) => c.id === contactId);
    if (contactIndex !== -1) {
      state.splice(contactIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET_CONTACTS") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const Contacts = () => {
  // !
  // Context
  const { user } = useContext(AuthContext);
  const SocketManager = useContext(SocketContext);
  // Reducer
  const [contacts, dispatch] = useReducer(reducer, []);
  // Ref
  const fileUploadRef = useRef(null);
  // State
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [importContact, setImportingContact] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  // Styles
  const classes = useStyles();

  // @
  useEffect(() => {
    dispatch({ type: "RESET_CONTACTS" });

    setPageNumber(1);
  }, []);

  // @ Fetch contacts
  useEffect(() => {
    setLoading(true);

    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data: whatsappData } = await api.get("/contacts", {
            params: { channel: "whatsapp", type: "list", searchParam, pageNumber },
          });

          dispatch({ type: "RESET_CONTACTS" });
          dispatch({ type: "LOAD_CONTACTS", payload: whatsappData.contacts });

          setLoading(false);
          setHasMore(whatsappData.hasMore);
        } catch (err) {
          console.error(err);
          toastError(err);
        }
      };

      fetchContacts();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const companyId = user.companyId || user.company_id || user.company;
    const userId = user.id;
    const socket = SocketManager.getSocket(companyId, userId);

    // socket.on("contact", (data) => {
    socket.on(`company-${companyId}-contact`, async (data) => {
      if (data.action === "update" || data.action === "create") {
        if(user.profile !== "agente"){
          dispatch({ type: "UPDATE_CONTACTS", payload: data.contact });
        } else if (user.profile === "agente" && data.contact.agentId === user.id) {
          dispatch({ type: "UPDATE_CONTACTS", payload: data.contact });
        }
        
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_CONTACT", payload: +data.contactId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [SocketManager, user]);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  // # Pagination
  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };
  const handleScroll = (e) => {
    if (!hasMore || loading) {
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  // # Modal
  const handleOpenContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(true);
  };
  const handleCloseContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(false);
  };
  const handleEditContact = (contactId) => {
    setSelectedContactId(contactId);
    setContactModalOpen(true);
  };

  // # Import
  const handleImportContact = async () => {
    try {
      if (fileUploadRef.current.files[0]) {
        const formData = new FormData();
        formData.append("file", fileUploadRef.current.files[0]);
        await api.request({
          url: "/contacts/upload",
          method: "POST",
          data: formData,
        });
      } else {
        await api.post("/contacts/import");
      }

      setImportingContact(null);
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <MainContainer className={classes.mainContainer}>
      <ContactModal
        open={contactModalOpen}
        onClose={handleCloseContactModal}
        aria-labelledby="form-dialog-title"
        contactId={selectedContactId}
      ></ContactModal>

      <ConfirmationModal
        title={i18n.t("contacts.whatsapp.confirmationModal.importTitlte")}
        open={confirmOpen}
        onClose={setConfirmOpen}
        onConfirm={() => handleImportContact()
        }
      >
        {importContact
          
          ? `${i18n.t("contacts.whatsapp.confirmationModal.importExcelMessage")}`
          : `${i18n.t("contacts.whatsapp.confirmationModal.importMessage")}`}
      </ConfirmationModal>

      <MainHeader>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("contacts.whatsapp.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            style={{ width: "400px" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          {user.profile !== "agente" && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setConfirmOpen(true);           
              }}
            >
              {i18n.t("contacts.buttons.import")}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              fileUploadRef.current.value = null;
              fileUploadRef.current.click();
              setImportingContact(true);
            }}
          >
            {i18n.t("contacts.buttons.importSheet")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenContactModal}
          >
            {i18n.t("contacts.whatsapp.buttons.add")}
          </Button>
          <CSVLink style={{ textDecoration:"none"}} separator=";" filename={"contatos.xls"} data={contacts.map((contact) => ({ Nome: contact.name, Numero: contact.number, Email: contact.email }))}>
            <Button	variant="contained" color="primary"> 
              EXPORTAR CONTATOS 
            </Button>
          </CSVLink>	
        </MainHeaderButtonsWrapper>
      </MainHeader>

      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
        style={{ marginBottom: "20px" }}
      >
        <input
          style={{ display: "none" }}
          id="upload"
          name="file"
          type="file"
          accept=".xls,.xlsx"
          onChange={() => {
            setConfirmOpen(true);
          }}
          ref={fileUploadRef}
        />
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell>{i18n.t("contacts.whatsapp.table.name")}</TableCell>
              <TableCell align="center">
                {i18n.t("contacts.whatsapp.table.number")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("contacts.whatsapp.table.email")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("contacts.whatsapp.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {contacts.map((contact) => (
                <TableRow key={contact.id}>
                  <TableCell style={{ paddingRight: 0 }}>
                    {<Avatar src={contact.profilePicUrl} />}
                  </TableCell>
                  <TableCell>{contact.name}</TableCell>
                  <TableCell align="center">{contact.number}</TableCell>
                  <TableCell align="center">{contact.email}</TableCell>
                  <TableCell align="center">
                    {/*  <IconButton
                      size="small"
                      onClick={() => handleSaveTicket(contact.id)}
                    >
                      <WhatsAppIcon />
                    </IconButton> */}
                    <IconButton
                      size="small"
                      onClick={() => handleEditContact(contact.id)}
                    >
                      <EditIcon />
                    </IconButton>
                    {/* <Can
                      role={user.profile}
                      perform="contacts-page:deleteContact"
                      yes={() => (
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            setConfirmOpen(true);
                            setDeletingContact(contact);
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      )}
                    /> */}
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton avatar columns={3} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Contacts;
