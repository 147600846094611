import React, { useEffect, useRef, useContext, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { Tooltip } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import { CheckCircle } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import ExpandMore from "@material-ui/icons/ExpandMore";
import TicketOptionsMenu from "./TicketOptionsMenu";
// assets
import whatsappIcon from "../assets/channels/WhatsApp_Glyph_Green.png";
// context
import { AuthContext } from "../context/Auth/AuthContext";
// errors
// import toastError from "../errors/toastError";
// components
// import ButtonWithSpinner from "./ButtonWithSpinner";
import MarkdownWrapper from "./MarkdownWrapper";
// services
// import api from "../services/api";
// translate
import { i18n } from "../translate/i18n";

const useStyles = makeStyles(() => ({
  ticket: {
    position: "relative",
    "&:hover $ticketOptionsButton": {
      display: "inline-flex",
      opacity: 1,
    },
  },

  pendingTicket: {
    cursor: "unset",
  },

  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },

  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  primaryWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  secondaryWrapper: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  secondaryTop: {
    display: "flex",
    height: 26,
    width: "100%",
  },
  secondaryBottom: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  contactNameText: {
    flexGrow: 1,
  },

  lastMessageTime: {
    justifySelf: "flex-end",
    lineHeight: "1.7",
  },
  ticketOptionsButton: {
    marginLeft: 5,
    display: "none",
    transition: "display 0.3s, opacity 0.3s",
  },

  statusBadge: {
    alignSelf: "center",
    marginRight: 5,
    "& span": {
      transform: "unset",
      transformOrigin: "unset",
      position: "relative"
    }
  },

  contactLastMessage: {
    paddingRight: 20,
    marginBottom: 5,
  },

  newMessagesCount: {
    alignSelf: "center",
    marginRight: 8,
    marginLeft: "auto",
  },

  badgeStyle: {
    color: "white",
    backgroundColor: green[500],
  },

  acceptButton: {
    position: "absolute",
    left: "50%",
  },

  ticketQueueColor: {
    flex: "none",
    width: "8px",
    height: "100%",
    position: "absolute",
    top: "0%",
    left: "0%",
  },

  queueName: {
    marginRight: 5,
    border: "1px solid #CCC",
    color: "#585858",
    padding: 1,
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 10,
    fontSize: "0.9em",
    height: "22px",
    whiteSpace: "nowrap",
  },
  leftInfo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  rightInfo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  agentNameIcon: {
    marginRight: 4,
    height: 16,
  },
  agentName: {
    display: "flex",
    alignItems: "center",
    padding: "1px 5px 1px 2px",
    borderRadius: 10,
    fontSize: "0.9em",
    border: "1px solid #CCC",
  },
  userTag: {
    marginLeft: 5,
    marginRight: 5,
    background: "#2576D2",
    color: "#ffffff",
    border: "1px solid #CCC",
    padding: 1,
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 10,
    fontSize: "0.9em",
    height: "22px",
    whiteSpace: "nowrap",
  }
}));

const TicketListItem = ({ ticket }) => {
  // !
  // Context
  const { user } = useContext(AuthContext);
  // History
  const history = useHistory();
  // Params
  const { ticketId } = useParams();
  // Ref
  const isMounted = useRef(true);
  // State
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTicket, setSelectedTicket] = useState(null);
  // const [showOptionsButton, setShowOptionsButton] = useState(false);
  // const [loading, setLoading] = useState(false);
  // Styles
  const classes = useStyles();

  let kanban = 0;
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  kanban = query.get("kanban");


  // @
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  /*
  const handleAcepptTicket = async id => {
    setLoading(true);

    try {
      await api.put(`/tickets/${id}`, {
        status: "open",
        userId: user?.id,
      }, {
        params: {
          channel: ticket.channel
        }
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }

    if (isMounted.current) {
      setLoading(false);
    }

    history.push(`/tickets/${id}`);
  };
  */

  const handleSelectTicket = id => {
    if(kanban === "1") {
      history.push(`/tickets/${id}?kanban=1`);
    } else {
      history.push(`/tickets/${id}?kanban=0`);
    }
    
  };

  const handleOpenTicketOptionsMenu = (e, ticket) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget.parentElement);
    setSelectedTicket(ticket);
  };

  const handleCloseTicketOptionsMenu = () => {
    setAnchorEl(null);
    setSelectedTicket(null);
  };

  const isLightColor = (color) => {
    const hex = color.replace("#", "");
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    return brightness > 155;
  };

  return (
    <React.Fragment key={ticket.id + "whatsapp"}>
      <ListItem
        id={`ticketListItem-${ticket.id}`}
        dense
        button
        onClick={() => {
          if (ticket.status === "pending" && user.profile !== "supervisor") return;
          handleSelectTicket(ticket.id);
        }}
        selected={ticketId && +ticketId === ticket.id}
        className={clsx(classes.ticket, {
          [classes.pendingTicket]: ticket.status === "pending",
        })}
      >
        <Tooltip
          arrow
          placement="right"
          title={ticket.queue?.name || "Sem fila"}
        >
          <span
            style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }}
            className={classes.ticketQueueColor}
          ></span>
        </Tooltip>

        <ListItemAvatar>
          <Avatar src={ticket?.contact?.profilePicUrl} />
        </ListItemAvatar>

        <ListItemText
          disableTypography
          primary={
            <span className={classes.primaryWrapper}>
              <Typography
                className={classes.contactNameText}
                noWrap
                component="span"
                variant="body2"
                color="textPrimary"
              >
                {ticket.contact.name}
              </Typography>

              {ticket.lastMessage && (
                <Typography
                  className={classes.lastMessageTime}
                  component="span"
                  variant="body2"
                  color="textSecondary"
                >
                  {isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
                    <>{format(parseISO(ticket.updatedAt), "HH:mm")}</>
                  ) : (
                    <>{format(parseISO(ticket.updatedAt), "dd/MM/yyyy")}</>
                  )}
                </Typography>
              )}
            </span>
          }
          secondary={
            <span className={classes.secondaryWrapper}>
              <span className={classes.secondaryTop}>
                {/* Last message */}
                <Typography
                  className={classes.contactLastMessage}
                  noWrap
                  component="span"
                  variant="body2"
                  color="textSecondary"
                >
                  {ticket.lastMessage ? (
                    <MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>
                  ) : (
                    <br />
                  )}
                </Typography>

                {/* Unread messages count */}
                <Badge
                  overlap="circular"
                  className={classes.newMessagesCount}
                  badgeContent={(ticket.unreadMessages > 0) ? ticket.unreadMessages : (ticket.unreadMessages < 0 ? " " : null)}
                  classes={{
                    badge: classes.badgeStyle,
                  }}
                />

                {/* Button to open TicketOptionsMenu */}
                <IconButton
                  size="small"
                  id={`ticketOptionsButton-${ticket.id}`}
                  className={classes.ticketOptionsButton}
                  onClick={(e) => handleOpenTicketOptionsMenu(e, ticket)}
                >
                  <ExpandMore fontSize="small" />
                </IconButton>
              </span>

              <span className={classes.secondaryBottom}>
                <div className={classes.leftInfo}>
                  {/* Sector name */}
                  {ticket.queue && (
                    <Tooltip
                      arrow
                      placement="right"
                      title={i18n.t("ticketsList.queueTooltip")}
                    >
                      <div
                        className={classes.queueName}
                        style={{
                          borderColor: ticket.queue?.color,
                          boxShadow: isLightColor(ticket.queue?.color) ? "0 0 0 1px #585858" : "none",
                        }}
                      >
                        {ticket.queue?.name}
                      </div>
                    </Tooltip>
                  )}

                  {/* Ticket status */}
                  {ticket.status === "pending" && (
                    <div></div>
                  )}
                  {ticket.status === "open" && (
                    <div></div>
                  )}
                  {ticket.status === "closed" && (
                    <Tooltip
                      arrow
                      placement="right"
                      title={i18n.t("ticketsList.statusClosedTooltip")}
                    >
                      <CheckCircle style={{ height: 16, color: "#f44336" }} />
                    </Tooltip>
                  )}
                </div>

                <div className={classes.rightInfo}>
                  {/* Agent name */}
                  {user.profile !== "agente" && ticket.user && (
                    <Tooltip
                      arrow
                      placement="top"
                      title={i18n.t("ticketsList.agentTooltip")}
                    >
                      <div className={classes.agentName}>
                        <HeadsetMicIcon className={classes.agentNameIcon} />
                        {ticket.user?.name}
                      </div>
                    </Tooltip>
                  )}

                  {/* Connection name + icon */}
                  {ticket.whatsappId && (
                    <>
                      <Tooltip
                        arrow
                        placement="top"
                        title={i18n.t("ticketsList.connectionTitle")}
                      >
                        <div className={classes.userTag}>
                          {ticket.whatsapp?.name}
                        </div>
                      </Tooltip>
                      <Tooltip
                        arrow
                        placement="top"
                        title={i18n.t("ticketsList.channelTooltip")}
                      >
                        <img alt="Channel" src={whatsappIcon} width="16px" height="16px" />
                      </Tooltip>
                    </>
                  )}
                </div>
              </span>
            </span>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      {selectedTicket && (
        <TicketOptionsMenu
          ticket={selectedTicket}
          anchorEl={anchorEl}
          handleClose={handleCloseTicketOptionsMenu}
        />
      )}
    </React.Fragment>
  );
};
TicketListItem.propTypes = {
  ticket: PropTypes.object.isRequired
};

export default TicketListItem;
