import "date-fns";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { format } from "date-fns";

import { Button, TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import api from "../../services/api";

import "./button.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      display: false,
    },
    title: {
      display: true,
      text: "Grafico de Conversas",
      position: "left",
    },
    datalabels: {
      display: true,
      anchor: "start",
      offset: -30,
      align: "start",
      color: "#fff",
      textStrokeColor: "#000",
      textStrokeWidth: 2,
      font: {
        size: 20,
        weight: "bold"

      },
    }
  },
};

export const ChartsDate = () => {

  const [initialDate, setInitialDate] = useState(new Date());
  const [finalDate, setFinalDate] = useState(new Date());
  const [ticketsData, setTicketsData] = useState({ data: [], count: 0 });

  useEffect(() => {
    handleGetTicketsInformation();
  }, []);

  const dataCharts = {

    labels: ticketsData && ticketsData?.data.length > 0 && ticketsData?.data.map((item) => (Object.prototype.hasOwnProperty.call(item, "horario") ? `Das ${item.horario}:00 as ${item.horario}:59` : item.data)),
    datasets: [
      {
        // label: 'Dataset 1',
        data: ticketsData?.data.length > 0 && ticketsData?.data.map((item) => {
          return item.total;
        }),
        backgroundColor: "#2DDD7F",
      },
    ],
  };

  const handleGetTicketsInformation = async () => {
    try {
      const { data } = await api.get(`/dashboard/ticketsDay?initialDate=${format(initialDate, "yyyy-MM-dd")}&finalDate=${format(finalDate, "yyyy-MM-dd")}`);
      setTicketsData(data);
    } catch (error) {
      toast.error("Erro ao buscar informações dos tickets");
    }
  };

  return (
    <>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        Total ({ticketsData?.count})
      </Typography>

      <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
        <form noValidate>
          <TextField
            id="initial-date"
            label="Inicial"
            type="date"
            value={format(initialDate, "yyyy-MM-dd")}
            onChange={(e) => setInitialDate(new Date(e.target.value))}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ marginRight: "16px", width: "20ch" }}
          />
          <TextField
            id="final-date"
            label="Final"
            type="date"
            value={format(finalDate, "yyyy-MM-dd")}
            onChange={(e) => setFinalDate(new Date(e.target.value))}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ marginRight: "16px", width: "20ch" }}
          />
        </form>

        <Button variant="contained" color="primary" onClick={handleGetTicketsInformation}>Filtrar</Button>
      </div>
      <Bar options={options} data={dataCharts} style={{ maxWidth: "100%", maxHeight: "280px", }} />
    </>
  );
};