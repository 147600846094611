import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field, FieldArray } from "formik";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Checkbox } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
// components
import TicketsAgentsSelect from "./TicketsAgentsSelect";
// context
import { AuthContext } from "../context/Auth/AuthContext.js";
// errors
import toastError from "../errors/toastError";
// services
import api from "../services/api";
// translate
import { i18n } from "../translate/i18n";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },

  extraAttr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ContactSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Muito curto! No minimo 2 caracteres")
    .max(50, "Muito longo! No máximo 50 caracteres")
    .required("Required"),
  number: Yup.string().min(8, "Muito curto! No minimo 8 digitos").max(50, "Muito longo! No máximo 50 digitos"),
  email: Yup.string().email("E-mail inválido"),
  cpfCnpj: Yup.string(),
  observations: Yup.string().nullable().max(512, "Muito longo! No máximo 512 caracteres"),
  extraInfo: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Required"),
      value: Yup.string().required("Required"),
    })
  ),
});

const ContactModal = ({ open, onClose, contactId, initialValues, onSave }) => {
  // !
  // Context
  const { user } = useContext(AuthContext);
  // Ref
  const isMounted = useRef(true);
  // State
  const initialState = {
    name: "",
    number: "",
    email: "",
    cpfCnpj: "",
    isGroup: 0,
    observations: "", // Adicionado campo para observações
    extraInfo: [], // Adicionado campo para informações extras
  };
  const [contact, setContact] = useState(initialState);
  const [defineGroup, setDefineGroup] = useState(false);
  const [charCount, setCharCount] = useState(0); // Adicionado estado para contador de caracteres

  const [agentList, setAgentList] = useState([]);
  const [selectedAgentIds, setSelectedAgentIds] = useState([]);
  // Styles
  const classes = useStyles();

  // @
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // @ Fetch and set `AgentList`
  useEffect(() => {
    const fetchAgents = async () => {
      const { data } = await api.get("/users/");

      setAgentList(data.users);
    };

    fetchAgents();
  }, []);
  // @ Fetch contact
  useEffect(() => {
    const fetchContact = async () => {
      if (initialValues) {
        setContact(prevState => {
          return { ...prevState, ...initialValues };
        });
      }

      if (!contactId) return;

      try {
        const { data } = await api.get(`/contacts/${contactId}`);

        if (isMounted.current) {
          if (data.agentId) {
            setSelectedAgentIds(data.agentId);
          }

          setContact(data);
        }

        if(data.isGroup){
          setDefineGroup(true);
        } else {
          setDefineGroup(false);
        }
      } catch (err) {
        toastError(err);
      }
    };

    fetchContact();
  }, [contactId, open, initialValues]);

  const handleClose = () => {
    onClose();
    setContact(initialState);
  };

  const handleClearAgentSelection = () => {
    setSelectedAgentIds(null);
  };

  const handleSaveContact = async values => {
    try {
      if (contactId) { // @ Edit contact
        if (user.profile !== "agente" && typeof selectedAgentIds === "number") {
          values.agentId = selectedAgentIds;
        } else if (user.profile !== "agente" && typeof selectedAgentIds === "object") {
          values.agentId = selectedAgentIds;
        }

        await api.put(`/contacts/${contactId}`, values);

        handleClose();
      } else { // @ Add contact
        if (user.profile !== "agente" && typeof selectedAgentIds === "number") {
          values.agentId = selectedAgentIds;
        }
        const { data } = await api.post("/contacts/", values);

        if (onSave) {
          onSave(data);
        }

        handleClose();
      }

      toast.success(i18n.t("contactModal.success"));
    } catch (err) {
      toastError(err);
    }
  };

  const handleObservationsChange = (event, setFieldValue) => {
    setCharCount(event.target.value.length);
    setFieldValue("observations", event.target.value);
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
        <DialogTitle id="form-dialog-title">
          {contactId
            ? `${i18n.t("contactModal.title.edit")}`
            : `${i18n.t("contactModal.title.add")}`}
        </DialogTitle>

        <Formik
          initialValues={contact}
          enableReinitialize={true}
          validationSchema={ContactSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveContact(values);

              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, errors, touched, isSubmitting, setFieldValue }) => (
            <Form>
              <DialogContent dividers>
                <Typography variant="subtitle1" gutterBottom>
                  {i18n.t("contactModal.form.mainInfo")}
                </Typography>
                <Field
                  as={TextField}
                  label={i18n.t("contactModal.form.name")}
                  name="name"
                  autoFocus
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  variant="outlined"
                  margin="dense"
                  className={classes.textField}
                />
                <Field
                  as={TextField}
                  label={i18n.t("contactModal.form.number")}
                  name="number"
                  error={touched.number && Boolean(errors.number)}
                  helperText={touched.number && errors.number}
                  placeholder="DDI+DDD+TELEFONE"
                  variant="outlined"
                  margin="dense"
                  disabled={contactId}
                />
                <div>
                  <Field
                    as={TextField}
                    label={i18n.t("contactModal.form.email")}
                    name="email"
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    placeholder="Email"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                  />
                </div>
                <div>
                  <Field
                    as={TextField}
                    label={i18n.t("contactModal.form.cpfCnpj")}
                    name="cpfCnpj"
                    error={touched.cpfCnpj && Boolean(errors.cpfCnpj)}
                    helperText={touched.cpfCnpj && errors.cpfCnpj}
                    placeholder="CPF/CNPJ"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                  />
                </div>

                <div>
                  <Field
                    as={TextField}
                    label="Observações"
                    name="observations"
                    error={touched.observations && Boolean(errors.observations)}
                    helperText={touched.observations && errors.observations}
                    placeholder="Adicione suas observações aqui"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    multiline
                    rows={4}
                    inputProps={{ maxLength: 512 }}
                    onChange={(event) => {
                      handleObservationsChange(event, setFieldValue);
                    }}
                  />
                  <Typography variant="caption" display="block" align="right">
                    {charCount}/512
                  </Typography>
                </div>

                <Typography
                  style={{ marginBottom: 8, marginTop: 12 }}
                  variant="subtitle1"
                >
                  {i18n.t("contactModal.form.extraInfo")}
                </Typography>
                <FieldArray name="extraInfo">
                  {({ push, remove }) => (
                    <>
                      {values.extraInfo &&
                        values.extraInfo.length > 0 &&
                        values.extraInfo.map((info, index) => (
                          <div
                            className={classes.extraAttr}
                            key={`${index}-info`}
                          >
                            <Field
                              as={TextField}
                              label={i18n.t("contactModal.form.extraName")}
                              name={`extraInfo[${index}].name`}
                              variant="outlined"
                              margin="dense"
                              className={classes.textField}
                            />
                            <Field
                              as={TextField}
                              label={i18n.t("contactModal.form.extraValue")}
                              name={`extraInfo[${index}].value`}
                              variant="outlined"
                              margin="dense"
                              className={classes.textField}
                            />
                            <IconButton
                              size="small"
                              onClick={() => remove(index)}
                            >
                              <DeleteOutlineIcon />
                            </IconButton>
                          </div>
                        ))}
                      <div className={classes.extraAttr}>
                        <Button
                          style={{ flex: 1, marginTop: 8 }}
                          variant="outlined"
                          color="primary"
                          onClick={() => push({ name: "", value: "" })}
                        >
                          {`+ ${i18n.t("contactModal.buttons.addExtraInfo")}`}
                        </Button>
                      </div>
                    </>
                  )}
                </FieldArray>

                <div style={{ display: "flex", flexDirection: "column" }}>
                  {contactId && (
                    <>
                      <div style={{ display: "block", width: "100%" }}>
                        <Divider style={{ margin: "16px 0" }} />
                      </div>
                      <div style={{ display: "block", width: "100%" }}>
                        <Typography variant="subtitle1" gutterBottom style={{ margin: "4px 0 0 0" }}>
                          {i18n.t("contactModal.form.isgroup")}
                        </Typography>
                        <Checkbox
                          size="small"
                          checked={defineGroup}
                        />
                        <span style={{ margin: "10px 10px 0 0" }}>Sim</span>
                      </div>
                    </>
                  )}
                </div>

                <div style={{ display: "flex", flexDirection: "column" }}>
                  {user.profile !== "agente" && (
                    <>
                      <div style={{ width: "100%", display: "block" }}>
                        <Divider style={{ margin: "16px 0", width: "100%" }} />
                      </div>
                      <div style={{ display: "flex" }}>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          style={{ margin: "10px 10px 0 0" }}
                        >
                          Agente associado (carteirização):
                        </Typography>
                        <TicketsAgentsSelect
                          userAgents={agentList}
                          selectedAgentIds={selectedAgentIds}
                          onChange={(values) => setSelectedAgentIds(values)}
                          multiple={false}
                        />
                        <Button
                          variant="contained"
                          color="secondary"
                          startIcon={<ClearIcon />}
                          onClick={handleClearAgentSelection}
                          style={{ marginTop: "5px", marginBottom: "5px", marginLeft: "10px", paddingRight: "4px" }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </DialogContent>

              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("contactModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {contactId
                    ? `${i18n.t("contactModal.buttons.okEdit")}`
                    : `${i18n.t("contactModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};
ContactModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  contactId: PropTypes.number,
  initialValues: PropTypes.object,
  onSave: PropTypes.func,
};

export default ContactModal;
