import { useState, useEffect, useReducer, useContext } from "react";
// context
import { AuthContext } from "../../context/Auth/AuthContext";
import { SocketContext } from "../../context/Socket/SocketContext";
// errors
import toastError from "../../errors/toastError";
// services
import api from "../../services/api";

const reducer = (state, action) => {
  if (action.type === "LOAD_WHATSAPPS") {
    const whatsApps = action.payload;

    return [...whatsApps];
  }

  if (action.type === "UPDATE_WHATSAPPS") {
    const whatsApp = action.payload;
    const whatsAppIndex = state.findIndex(s => s.id === whatsApp.id);

    if (whatsAppIndex !== -1) {
      state[whatsAppIndex] = whatsApp;
      return [...state];
    } else {
      return [whatsApp, ...state];
    }
  }

  if (action.type === "UPDATE_SESSION") {
    const whatsApp = action.payload;
    const whatsAppIndex = state.findIndex(s => s.id === whatsApp.id);

    if (whatsAppIndex !== -1) {
      state[whatsAppIndex].status = whatsApp.status;
      state[whatsAppIndex].updatedAt = whatsApp.updatedAt;
      state[whatsAppIndex].qrcode = whatsApp.qrcode;
      state[whatsAppIndex].retries = whatsApp.retries;
      return [...state];
    } else {
      return [...state];
    }
  }

  if (action.type === "DELETE_WHATSAPPS") {
    const whatsAppId = action.payload;

    const whatsAppIndex = state.findIndex(s => s.id === whatsAppId);
    if (whatsAppIndex !== -1) {
      state.splice(whatsAppIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useWhatsApps = () => {
  // !
  // Context
  const { user, isAuth } = useContext(AuthContext);
  const SocketManager = useContext(SocketContext);
  // Reducer
  const [whatsApps, dispatch] = useReducer(reducer, []);
  // State
  const [loading, setLoading] = useState(true);

  // @
  useEffect(() => {
    setLoading(true);

    const fetchSession = async () => {
      try {

        if(user.profile === "agente") {
          const { data } = await api.get("/whatsapp/agent/");
          dispatch({ type: "LOAD_WHATSAPPS", payload: data });
        } else if(user.profile === "supervisor") {
          const { data } = await api.get("/whatsapp/supervisor/");
          dispatch({ type: "LOAD_WHATSAPPS", payload: data });
        } else {
          const { data } = await api.get("/whatsapp/all/");
          dispatch({ type: "LOAD_WHATSAPPS", payload: data });
        }

        setLoading(false);
      } catch (err) {
        setLoading(false);

        toastError(err);
      }
    };

    if (isAuth) {
      fetchSession();
    }
  }, [isAuth, user]);

  useEffect(() => {
    const companyId = user.companyId || user.company_id || user.company;
    const userId = user.id;

    const socket = SocketManager.getSocket(companyId, userId);

    // @
    // @ Receive events
    // whatsapp
    socket.on("whatsapp", data => {
      if (data.action === "update") {
        dispatch({ type: "UPDATE_WHATSAPPS", payload: data.whatsapp });
      }
    });
    socket.on("whatsapp", data => {
      if (data.action === "delete") {
        dispatch({ type: "DELETE_WHATSAPPS", payload: data.whatsappId });
      }
    });

    // whatsappSession
    socket.on("whatsappSession", data => {
      if (data.action === "update") {
        dispatch({ type: "UPDATE_SESSION", payload: data.session });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [user, SocketManager]);

  return { whatsApps, loading };
};

export default useWhatsApps;
