import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 320,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 320,
    display: "flex",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  header: {
    display: "flex",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    backgroundColor: "#eee",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    minHeight: "73px",
    justifyContent: "flex-start",
  },
  content: {
    display: "flex",
    backgroundColor: "#eee",
    flexDirection: "column",
    padding: "8px 0px 8px 8px",
    height: "100%",
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },

  search: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    margin: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#fff",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  results: {
    flex: 1,
    overflowY: "auto",
  },
  highlight: {
    fontWeight: "bold",
    backgroundColor: "yellow",
  },
  resultItem: {
    padding: theme.spacing(1),
    borderBottom: "1px solid #ddd",
  },
}));

const MessageSearchDrawer = ({ open, handleDrawerClose, searchTerm, searchResults, onSearch, contactName, onLoadMore, hasMore, onHighlightMessage }) => {
  // !
  // State
  const [term, setTerm] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [loading, setLoading] = useState(false);
  // Styles
  const classes = useStyles();

  useEffect(() => {
    const resultsDiv = document.querySelector(`.${classes.results}`);
    if (resultsDiv) {
      resultsDiv.scrollTop = 0;
    }
  }, [term]);

  const handleInputChange = (e) => {
    const newTerm = e.target.value;

    setTerm(newTerm);

    if (newTerm && newTerm.length > 1) {
      setLoading(true);
    }

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(setTimeout(() => {
      onSearch(newTerm).finally(() => setLoading(false));
    }, 1000));
  };

  const handleClear = () => {
    setTerm("");
    onSearch("");
  };

  const highlightTerm = (text, term) => {
    if (!text) return null;
  
    // @ Normalize text and replace accents
    const normalizeText = (str) => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  
    const normalizedText = normalizeText(text);
    const normalizedTerm = normalizeText(term);
  
    const regex = new RegExp(`(${normalizedTerm})`, "gi");
    const parts = normalizedText.split(regex);
  
    return parts.map((part, index) =>
      part.toLowerCase() === normalizedTerm.toLowerCase() ? (
        <span key={index} className={classes.highlight}>
          {text.slice(parts.slice(0, index).join("").length, parts.slice(0, index + 1).join("").length)}
        </span>
      ) : (
        part
      )
    );
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffInMs = now - date;
    const diffInHours = diffInMs / (1000 * 60 * 60);
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    if (diffInHours < 24) {
      return date.toLocaleTimeString(undefined, { hour: "2-digit", minute: "2-digit" });
    } else if (diffInDays < 7) {
      return date.toLocaleDateString(undefined, { weekday: "long" });
    } else {
      return date.toLocaleDateString(undefined, { day: "2-digit", month: "2-digit", year: "numeric" });
    }
  };

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom && hasMore && !loading) {
      setLoading(true); // @ Set loading to true when loading more messages
      onLoadMore().finally(() => setLoading(false));
    }
  };

  const handleResultClick = (messageId) => {
    const messageElement = document.getElementById(`message-${messageId}`);
    if (messageElement) {
      messageElement.scrollIntoView({ behavior: "smooth" });
      onHighlightMessage(messageId); // @ Call onHighlightMessage when a message is clicked
    } else {
      const messagesList = document.getElementById("messagesList");
      if (messagesList) {
        messagesList.scrollTop = 1;
      }

      // @ Recall handleResultClick after 500ms
      setTimeout(() => handleResultClick(messageId), 500);
    }
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={open}
      PaperProps={{ style: { position: "absolute" } }}
      BackdropProps={{ style: { position: "absolute" } }}
      ModalProps={{
        container: document.getElementById("drawer-container"),
        style: { position: "absolute" },
      }}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.header}>
        <IconButton onClick={handleDrawerClose}>
          <CloseIcon />
        </IconButton>
        <Typography style={{ justifySelf: "center" }}>
          Buscar mensagens
        </Typography>
      </div>
      <div className={classes.content}>
        <Paper className={classes.search}>
          <InputBase
            className={classes.input}
            placeholder="Buscar..."
            value={term}
            onChange={handleInputChange}
          />
          {loading && term.length > 1 ? (
            <CircularProgress size={24} style={{ margin: 12 }} />
          ) : term ? (
            <IconButton onClick={handleClear}>
              <ClearIcon />
            </IconButton>
          ) : (
            <IconButton>
              <SearchIcon />
            </IconButton>
          )}
        </Paper>
        <div className={classes.results} onScroll={handleScroll}>
          {loading && term.length > 1 && searchResults.length === 0 ? (
            <Typography
              variant="body2"
              color="textSecondary"
              align="center"
              style={{ marginTop: 50 }}
            >
              Buscando por mensagens...
            </Typography>
          ) : (term === "" || term.length < 2) ? (
            <Typography
              variant="body2"
              color="textSecondary"
              align="center"
              style={{ marginTop: 50 }}
            >
              Buscar por mensagens dentro de <b>{contactName}</b>
            </Typography>
          ) : (
            searchResults.length === 0 ? (
              <Typography
                variant="body2"
                color="textSecondary"
                align="center"
                style={{ marginTop: 50 }}
              >
                Nenhuma mensagem encontrada
              </Typography>
            ) : (
              <List>
                {searchResults.map((result) => (
                  <ListItem button key={result.id} onClick={() => handleResultClick(result.id)} className={classes.resultItem}>
                    <ListItemText
                      primary={
                        <Typography variant="caption" color="textSecondary">
                          {formatDate(result.createdAt)}
                        </Typography>
                      }
                      secondary={highlightTerm(result.body, searchTerm)}
                    />
                  </ListItem>
                ))}
              </List>
            )
          )}
        </div>
      </div>
    </Drawer>
  );
};
MessageSearchDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  searchResults: PropTypes.array.isRequired,
  onSearch: PropTypes.func.isRequired,
  contactName: PropTypes.string.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  onHighlightMessage: PropTypes.func.isRequired,
};

export default MessageSearchDrawer;