import React from "react";
import PropTypes from "prop-types";
import { Card, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import { useHistory } from "react-router-dom";
// components
import TicketHeaderSkeleton from "./TicketHeaderSkeleton";

const useStyles = makeStyles(() => ({
  ticketHeader: {
    display: "flex",
    backgroundColor: "#eee",
    flex: "none",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  buttonText: {
    padding: 0,
  },
}));

const TicketHeader = ({ loading, children }) => {
  // !
  // History
  const history = useHistory();
  // Styles
  const classes = useStyles();

  // @
  const handleBack = () => {
    history.push("/tickets");
  };

  return (
    <>
      {loading ? (
        <TicketHeaderSkeleton />
      ) : (
        <Card square className={classes.ticketHeader}>
          <Button
            color="primary"
            onClick={handleBack}
            endIcon={<ArrowBackIos />}
            classes={{ text: classes.buttonText }}
          />
          {children}
        </Card>
      )}
    </>
  );
};
TicketHeader.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
};

export default TicketHeader;
