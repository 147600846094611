import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
// components
import ButtonWithSpinner from "./ButtonWithSpinner";
import ContactModal from "./ContactModal";
// context
import { AuthContext } from "../context/Auth/AuthContext";
// errors
import toastError from "../errors/toastError";
// hooks
import useWhatsApps from "../hooks/useWhatsApps";
// services
import api from "../services/api";
// translate
import { i18n } from "../translate/i18n";

const filter = createFilterOptions({
  trim: true,
});

const NewTicketModal = ({ modalOpen, onClose }) => {
  // !
  // Context
  const { user } = useContext(AuthContext);
  const { whatsApps } = useWhatsApps();
  // History
  const history = useHistory();
  // State
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [selectedContact, setSelectedContact] = useState(null);
  const [newContact, setNewContact] = useState({});
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [selectedQueue, setSelectedQueue] = useState("");
  const [queues, setQueues] = useState([]);
  const [selectedWhatsapp, setSelectedWhatsapp] = useState("");

  // @
  useEffect(() => {
    const loadQueues = async () => {
      // const list = await findAllQueues();
      const list = user.queues;

      setQueues(list);
    };

    loadQueues();
  }, [user]);


  // @
  useEffect(() => {
    if (!modalOpen || searchParam.length < 3) {
      setLoading(false);
      return;
    }

    setLoading(true);

    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("/contacts/", {
            params: { searchParam },
          });

          setOptions(data.contacts);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };

      fetchContacts();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, modalOpen]);

  const handleClose = () => {
    onClose();
    setSearchParam("");
    setSelectedContact(null);
  };

  const handleSaveTicket = async contactId => {
    if (!contactId) return;

    setLoading(true);
    try {
      const { data: ticket } = await api.post("/tickets", {
        contactId: contactId,
        userId: user.id,
        queueId: selectedQueue,
        status: "open",
        whatsappId: selectedWhatsapp,
      });

      history.push(`/tickets/${ticket.id}`);
    } catch (err) {
      toastError(err);
    }
    setLoading(false);

    handleClose();
  };

  const handleSelectOption = (e, newValue) => {
    if (newValue?.number) {
      setSelectedContact(newValue);
    } else if (newValue?.name) {
      setNewContact({ name: newValue.name });
      setContactModalOpen(true);
    }
  };

  const handleCloseContactModal = () => {
    setContactModalOpen(false);
  };

  const handleAddNewContactTicket = () => {
    handleClose();
  };

  const createAddContactOption = (filterOptions, params) => {
    const filtered = filter(filterOptions, params);

    if (params.inputValue !== "" && !loading && searchParam.length >= 3) {
      filtered.push({
        name: `${params.inputValue}`,
      });
    }

    return filtered;
  };

  const renderOption = option => {
    if (option.number) {
      return `${option.name} - ${option.number}`;
    } else {
      return `${i18n.t("newTicketModal.add")} ${option.name}`;
    }
  };

  const renderOptionLabel = option => {
    if (option.number) {
      return `${option.name} - ${option.number}`;
    } else {
      return `${option.name}`;
    }
  };

  return (
    <>
      <ContactModal
        open={contactModalOpen}
        initialValues={newContact}
        onClose={handleCloseContactModal}
        onSave={handleAddNewContactTicket}
      ></ContactModal>

      <Dialog open={modalOpen} onClose={handleClose}>
        <DialogTitle id="form-dialog-title">
          {i18n.t("newTicketModal.title")}
        </DialogTitle>

        <DialogContent dividers style={{ marginTop: 20, width: 350 }}>
          <FormControl variant="outlined" style={{ marginTop: 20, width: 300 }}>
            <Autocomplete
              options={options}
              loading={loading}
              style={{ width: 300 }}
              clearOnBlur
              autoHighlight
              freeSolo
              clearOnEscape
              getOptionLabel={renderOptionLabel}
              renderOption={renderOption}
              filterOptions={createAddContactOption}
              onChange={(e, newValue) => handleSelectOption(e, newValue)}
              renderInput={params => (
                <TextField
                  {...params}
                  label={i18n.t("newTicketModal.fieldLabel")}
                  variant="outlined"
                  autoFocus
                  onChange={e => setSearchParam(e.target.value)}
                  onKeyPress={e => {
                    if (loading || !selectedContact) return;
                    else if (e.key === "Enter") {
                      handleSaveTicket(selectedContact.id);
                    }
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
          <FormControl variant="outlined" style={{ marginTop: 20, width: 300 }}>
            <InputLabel>Selecione o setor</InputLabel>
            <Select
              value={selectedQueue}
              onChange={(e) => setSelectedQueue(e.target.value)}
              label="Setor"
            >
              <MenuItem value={""}>&nbsp;</MenuItem>
              {queues.map((queue) => (
                <MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" style={{ marginTop: 20, width: 300 }}>
            <InputLabel>Selecione uma conexão</InputLabel>
            <Select
              value={selectedWhatsapp}
              onChange={(e) => setSelectedWhatsapp(e.target.value)}
              label="Conexão"
            >
              {whatsApps.map((whasapp) => (
                <MenuItem key={whasapp.id} value={whasapp.id}>{whasapp.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleClose}
            color="secondary"
            disabled={loading}
            variant="outlined"
          >
            {i18n.t("newTicketModal.buttons.cancel")}
          </Button>
          <ButtonWithSpinner
            variant="contained"
            type="button"
            disabled={!selectedContact}
            onClick={() => handleSaveTicket(selectedContact.id)}
            color="primary"
            loading={loading}
          >
            {i18n.t("newTicketModal.buttons.ok")}
          </ButtonWithSpinner>
        </DialogActions>
      </Dialog>
    </>
  );
};
NewTicketModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};


export default NewTicketModal;
