import React from "react";
import PropTypes from "prop-types";

import { Avatar, CardHeader, IconButton } from "@material-ui/core";

import { FileCopy } from "@material-ui/icons";

import { makeStyles } from "@material-ui/core/styles";

// translate
import { i18n } from "../translate/i18n";

const useStyles = makeStyles(() => ({
  copyToClipboardIcon: {
    fontSize: 14,
  },
  cardHeader: {
    paddingLeft: 0,
  },
}));

const TicketInfo = ({ contact, user, ticket, onClick }) => {
  const classes = useStyles();

  const formatCreatedAt = (createdAt) => {
    const date = new Date(createdAt);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();

    return `${year}${month}${day}${hours}`;
  };

  const copyTicketIdToClipboard = async (event) => {
    event.stopPropagation();
    try {
      await navigator.permissions.query({ name: "clipboard-write" });
      await navigator.clipboard.writeText(ticket.id);

      alert("Protocolo copiado para a área de transferência!");
    } catch (err) {
      console.error("Falha ao copiar para a área de transferência: ", err);
    }
  };

  return (
    <CardHeader
      onClick={onClick}
      style={{ cursor: "pointer" }}
      titleTypographyProps={{ noWrap: true }}
      subheaderTypographyProps={{ noWrap: true }}
      avatar={<Avatar src={contact.profilePicUrl} alt="contact_image" />}
      title={contact.name}
      subheader={
        [
          <span key="ticketId" style={{ marginRight: 5 }}>
          Protocolo: <strong>{formatCreatedAt(ticket.createdAt)}{ticket.id}</strong>
          </span>,
          <IconButton
            key="copy"
            size="small"
            variant="outlined"
            onClick={copyTicketIdToClipboard}
          >
            <FileCopy size="small" className={classes.copyToClipboardIcon} />
          </IconButton>,
          (user.profile !== "agente" && ticket.user) && [
            <br key="break" />,
            <span key="assignedTo">{i18n.t("messagesList.header.assignedTo")} <strong>{ticket.user.name}</strong></span>
          ]
        ]
      }
      classes={{ root: classes.cardHeader }}
    />
  );
};
TicketInfo.propTypes = {
  contact: PropTypes.object,
  user: PropTypes.object,
  ticket: PropTypes.object,
  onClick: PropTypes.func,
};

export default TicketInfo;
