import React, { useEffect, useRef, useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { has, get, isNull } from "lodash";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import {
  Chip,
  Grid,
  LinearProgress,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination
} from "@material-ui/core";
import ButtonWithSpinner from "../../components/ButtonWithSpinner";
import GroupIcon from "@material-ui/icons/Group";
import ScheduleIcon from "@material-ui/icons/Schedule";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import DoneIcon from "@material-ui/icons/Done";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import ListAltIcon from "@material-ui/icons/ListAlt";
import CircularProgressWithLabel from "../../components/CircularProgressWithLabel";
// components
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import CardCounter from "../../components/Dashboard/CardCounter";
// context
import { AuthContext } from "../../context/Auth/AuthContext";
import { SocketContext } from "../../context/Socket/SocketContext";
// hooks
import { useDate } from "../../hooks/useDate";
// services
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(2),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  textRight: {
    textAlign: "right",
  },
  tabPanelsContainer: {
    padding: theme.spacing(2),
  },

  table: {
    marginTop: theme.spacing(2),
  },
}));

const CampaignReport = () => {
  // !
  // Context
  const { user } = useContext(AuthContext);
  const SocketManager = useContext(SocketContext);
  // Params
  const { campaignId } = useParams();
  // History
  const history = useHistory();
  // Refs
  const mounted = useRef(true);
  // States
  const [campaign, setCampaign] = useState({});
  const [validContacts, setValidContacts] = useState(0);
  const [delivered, setDelivered] = useState(0);
  const [confirmationRequested, setConfirmationRequested] = useState(0);
  const [confirmed, setConfirmed] = useState(0);
  const [percent, setPercent] = useState(0);
  const [loading, setLoading] = useState(false);

  const [contacts, setContacts] = useState([]);
  const [shipping, setShipping] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // Styles
  const classes = useStyles();

  const { datetimeToClient } = useDate();

  const findCampaign = async () => {
    setLoading(true);

    const { data } = await api.get(`/campaigns/${campaignId}`);

    // # Campaign
    setCampaign(data);
    // # CampaignContacts
    if (data.contactList && data.contactList.contacts) {
      setContacts(data.contactList.contacts);
    }
    // # CampaignShipping
    if (data.shipping) {
      setShipping(data.shipping);
    }

    setLoading(false);
  };

  // @
  useEffect(() => {
    if (mounted.current) {
      findCampaign();
    }

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (mounted.current && has(campaign, "shipping")) {
      if (has(campaign, "contactList")) {
        const contactList = get(campaign, "contactList");
        const valids = contactList.contacts.filter((c) => c.isWhatsappValid);
        setValidContacts(valids.length);
      }

      if (has(campaign, "shipping")) {
        const contacts = get(campaign, "shipping");
        const delivered = contacts.filter((c) => !isNull(c.deliveredAt));
        const confirmationRequested = contacts.filter(
          (c) => !isNull(c.confirmationRequestedAt)
        );
        const confirmed = contacts.filter(
          (c) => !isNull(c.deliveredAt) && !isNull(c.confirmationRequestedAt)
        );
        setDelivered(delivered.length);
        setConfirmationRequested(confirmationRequested.length);
        setConfirmed(confirmed.length);
        setDelivered(delivered.length);
      }
    }
  }, [campaign]);

  useEffect(() => {
    setPercent((delivered / validContacts) * 100);
  }, [delivered, validContacts]);

  useEffect(() => {
    const companyId = user.companyId || user.company_id || user.company;
    const userId = user.id;

    const socket = SocketManager.getSocket(companyId, userId);

    socket.on(`company-${companyId}-campaign`, (data) => {
      if (data.record.id === +campaignId) {
        setCampaign(data.record);

        if (data.record.contactList && data.record.contactList.contacts) {
          setContacts(data.record.contactList.contacts);
        }
  
        if (data.record.shipping) {
          setShipping(data.record.shipping);
        }

        if (data.record.status === "FINALIZADA") {
          setTimeout(() => {
            findCampaign();
          }, 5000);
        }
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [campaignId, user, SocketManager]);

  const formatStatus = (val) => {
    switch (val) {
      case "INATIVA":
        return "Inativa";
      case "PROGRAMADA":
        return "Programada";
      case "EM_ANDAMENTO":
        return "Em Andamento";
      case "CANCELADA":
        return "Cancelada";
      case "FINALIZADA":
        return "Finalizada";
      default:
        return val;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleCampaign = () => {  
    // console.log("Clicked on card with ID:", id);
    history.push("/campaigns/");
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <MainContainer>
      <MainHeader>
        <Grid style={{ width: "99.6%" }} container>
          <Grid xs={12} item>
            <Title>Relatório da {campaign.name || "Campanha"}{" "}
              {campaign.userId && user.profile !== "agente" ? `- Criada pelo usuário: ${campaign.user.name}` : ""}
            </Title>
          </Grid>
        </Grid>
      </MainHeader>

      <Paper className={classes.mainPaper} variant="outlined">
        <Grid spacing={2} container>
          <Grid xs={12} item>
            <Paper style={{ padding: "10px", marginBottom: "10px" }}>
              <Typography variant="h6" component="h2" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  Status: 
                  <Chip
                    label={`${formatStatus(campaign.status)}`}
                    color={campaign.status === "FINALIZADA" ? "primary" : "default"}
                    style={{ marginLeft: "10px" }}
                  />
                  <Chip
                    label={`${delivered} de ${validContacts}`}
                    style={{ marginLeft: "10px", marginRight: "10px" }}
                  />
                  <CircularProgressWithLabel value={percent} />
                </div>
                <ButtonWithSpinner
                  loading={loading}
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleCampaign}
                >
                  Voltar p/ Campanhas
                </ButtonWithSpinner>
              </Typography>
              <LinearProgress
                variant={percent === 0 ? "indeterminate" : "determinate"}
                style={{ height: 15, borderRadius: 3, margin: "20px 0" }}
                value={percent}
              />
            </Paper>
          </Grid>

          <Grid xs={12} md={4} item>
            <CardCounter
              icon={<GroupIcon fontSize="inherit" />}
              title="Contatos Válidos"
              value={validContacts}
              loading={loading}
            />
          </Grid>
          {campaign.confirmation && (
            <>
              <Grid xs={12} md={4} item>
                <CardCounter
                  icon={<DoneIcon fontSize="inherit" />}
                  title="Confirmações Solicitadas"
                  value={confirmationRequested}
                  loading={loading}
                />
              </Grid>
              <Grid xs={12} md={4} item>
                <CardCounter
                  icon={<DoneAllIcon fontSize="inherit" />}
                  title="Confirmações"
                  value={confirmed}
                  loading={loading}
                />
              </Grid>
            </>
          )}
          <Grid xs={12} md={4} item>
            <CardCounter
              icon={<CheckCircleIcon fontSize="inherit" />}
              title="Entregues"
              value={delivered}
              loading={loading}
            />
          </Grid>
          {campaign.whatsappId && (
            <Grid xs={12} md={4} item>
              <CardCounter
                icon={<WhatsAppIcon fontSize="inherit" />}
                title="Conexão"
                value={campaign.whatsapp.name}
                loading={loading}
              />
            </Grid>
          )}
          {campaign.contactListId && (
            <Grid xs={12} md={4} item>
              <CardCounter
                icon={<ListAltIcon fontSize="inherit" />}
                title="Lista de Contatos"
                value={campaign.contactList.name}
                loading={loading}
              />
            </Grid>
          )}
          <Grid xs={12} md={4} item>
            <CardCounter
              icon={<ScheduleIcon fontSize="inherit" />}
              title="Agendamento"
              value={datetimeToClient(campaign.scheduledAt)}
              loading={loading}
            />
          </Grid>
          <Grid xs={12} md={4} item>
            <CardCounter
              icon={<EventAvailableIcon fontSize="inherit" />}
              title="Conclusão"
              value={datetimeToClient(campaign.completedAt)}
              loading={loading}
            />
          </Grid>
        </Grid>

        <TableContainer className={classes.table} component={Paper}>
          <Typography
            variant="h6"
            component="h6"
            style={{ padding: "10px 20px" }}
          >
            Fila de Entregas:
          </Typography>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nome do contato</TableCell>
                <TableCell>Número do contato</TableCell>
                <TableCell>Agendado para</TableCell>
                <TableCell>Entregue em</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {shipping.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((ship) => {
                const contact = contacts.find((c) => c.id === ship.contactId);
                return (
                  <TableRow key={ship.id}>
                    <TableCell>{contact ? contact.name : "Desconhecido"}</TableCell>
                    <TableCell>{contact ? contact.number : "Desconhecido"}</TableCell>
                    <TableCell>{ship.scheduledAt ? datetimeToClient(ship.scheduledAt) : "Não Agendado"}</TableCell>
                    <TableCell>{ship.deliveredAt ? datetimeToClient(ship.deliveredAt) : "Não Entregue"}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={shipping.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Paper>
    </MainContainer>
  );
};

export default CampaignReport;
